<template>
  <div class="position-relative h-100 w-100">
    <div class="position-absolute d-flex justify-content-center font-weight-bold w-100 text-muted" style="bottom: 10%;">
      <div class="rounded bg-white align-self-center border border-secondary p-2 flex-wrap">
        <font-awesome-icon icon="arrow-alt-circle-left" size="2x"
                           class="link"
                           @click="prev"/>
        <font-awesome-icon icon="times" size="2x"
                           class="mx-5 link"
                           @click="close"/>
        <font-awesome-icon icon="arrow-alt-circle-right" size="2x"
                           class="link"
                           @click="next"/>
      </div>
    </div>
    <div class="position-absolute text-center w-100 link small" style="bottom: 0">
      <a href="https://www.instagram.com/spaboot_sauna" target="_blank">
        mehr Bilder auf
        <font-awesome-icon :icon="['fab', 'instagram']"/>
        Instagram
      </a>
    </div>
    <div class="d-table w-100 h-100">
      <div v-if="shuffledImageInfos.length > 0"
           class="d-table-cell text-center" style="vertical-align: middle;"
           @click="next">
        <mq-layout mq="sm">
          <cld-image
              v-if="!loading "
              ref="image"
              crop="scale"
              :height="shuffledImageInfos[imageIndex].aspectRatio <= 1 ? 500 : null"
              :width="shuffledImageInfos[imageIndex].aspectRatio > 1 ? 350 : null"
              :public-id="shuffledImageInfos[imageIndex].publicId"
              dpr="2.0">
            <cld-transformation quality="auto:low"/>
          </cld-image>
        </mq-layout>
        <mq-layout mq="md+">
          <cld-image
              v-if="!loading "
              ref="image"
              crop="scale"
              :height="shuffledImageInfos[imageIndex].aspectRatio <= 1 ? 500 : null"
              :width="shuffledImageInfos[imageIndex].aspectRatio > 1 ? 950 : null"
              :public-id="shuffledImageInfos[imageIndex].publicId">
            <cld-transformation quality="auto:low"/>
          </cld-image>
        </mq-layout>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: {
    back: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      imageInfos: [],
      imageIndex: 0,
      loading: false
    }
  },
  computed: {
    shuffledImageInfos() {
      const a = [...this.imageInfos];
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    }
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),
    next() {
      this.loading = true
      this.startLoading()
      this.imageIndex = (this.imageIndex + 1) % (this.imageInfos.length - 1)
      this.$nextTick(() => {
        this.loading = false
        this.stopLoading()
      })
    },
    prev() {
      this.imageIndex = this.imageIndex === 0 ? this.imageInfos.length - 1 : this.imageIndex - 1
    },
    close() {
      this.$router.replace({name: this.back})
    }
  },
  mounted() {
    this.startLoading()
    this.$api.get('/images', {
      params: {
        folder: 'carousel'
      }
    }).then(res => {
      this.imageInfos = res.data
    }).catch(err => this.handleError(err))
        .finally(() => this.stopLoading())
  }
}
</script>
